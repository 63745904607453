export class User {
    userId:number;
    firstName: string;
    lastName: string;
    userEmail:string;
    diarizationSeconds: string;
    speechToTextSeconds: string;
    keywordsSeconds: string;
    keywordsCount: number;
    diacriticsRestWords: string;
    allowLogin: boolean;
    createdDate: string;
    password:string;

      
    constructor(data: any) {
      this.userId = data['userId'];
      this.firstName = data['firstName'];
      this.lastName = data['lastName'];
      this.userEmail = data['userEmail'];
      this.diarizationSeconds = data['diarizationSeconds'];
      this.speechToTextSeconds = data['speechToTextSeconds'];
      this.keywordsSeconds = data['keywordsSeconds'];
      this.keywordsCount = data['keywordsCount'];
      this.diacriticsRestWords = data['diacriticsRestWords'];
      this.allowLogin = data['allowLogin'];
      this.createdDate = data['createdDate'];
      this.password = data['password'];
     }

   }