<body (click)="checkTokenFromStorage()">

  <div class="table-button-container home"> 
      
      <button mat-fab color="warn" class="home-button" aria-label="Home" routerLink="/">
        <mat-icon>home</mat-icon>
      </button>

      <span class="files_text">{{ 'home.file' | translate }}</span>
      <mat-form-field appearance="outline" >
        <input (keyup) = "applyFilter($event.target)" matInput   placeholder="{{ 'home.searchFile' | translate }}">
      </mat-form-field>

  </div>


<div style = "height:95%">
  <div class="mat-elevation-z8 table-button-container table-files" style = "height:83%">
    <div class="table-scroolable" >
      <table mat-table [dataSource]="dataSource" class="table-container" matSort (matSortChange)="announceSortChange($event)">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by File Name">{{ 'home.fileName' | translate }}
          </th>

          <td mat-cell *matCellDef="let element" (click) = "downloadAudioFile(element.audioId, element.fileName)"  >  <a  class="downloadFile" matTooltip="{{ 'home.download' | translate }}" matTooltipPosition="left">{{element.fileName}}</a> </td>
        </ng-container>

        <ng-container matColumnDef="length">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'home.size' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.length |  parseTimeFormat}} </td>
        </ng-container>

        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'home.fileDate' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.createdDate}} </td>
        </ng-container>

        <ng-container matColumnDef="asrDomain">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'home.asrDomain' | translate }} </th>
          <td mat-cell *matCellDef="let element">{{element.asrDomain}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>  {{ 'home.status' | translate }}  </th>
            <td mat-cell *matCellDef="let element">
              <div [ngSwitch] = "element.status">
                <span *ngSwitchCase="'completed'" style="color:green">{{ 'home.statusTranscript.completed' | translate }}</span>
                <span *ngSwitchCase="'progress'" style="color:orange">{{ 'home.statusTranscript.inProgress' | translate }}</span>
		<span *ngSwitchCase="'error'" style="color:brown">{{ 'home.statusTranscript.error' | translate }}</span>
                <span *ngSwitchCase="'New'" style="color:red">{{ 'home.statusTranscript.new' | translate }}</span>
              </div>
            
            </td>
        </ng-container>

        <ng-container matColumnDef="actions" >
          <th mat-header-cell *matHeaderCellDef class="actions-column" style="text-align: center;padding-right: 160px;">  {{ 'home.actions' | translate }}  </th>
          <td mat-cell *matCellDef="let element" class="actions-column">
            <div class = "actions-container">


                
                
              <div class = "icon-container" >
                <fa-icon  class = "icon-actions" [icon]="faFolder" (click)="openModal('custom-modal-1', element)"
                  matTooltip="{{ 'home.requestTranscription' | translate }}" matTooltipPosition="left"></fa-icon>
                </div>

                <div class = "icon-container" [ngSwitch]="element.status">
                  <fa-icon *ngSwitchCase="'completed'"  class = "icon-actions" [icon]="faEye" 
                  (click)="navigateToTranscription(element.transcriptionJobId, element.audioId, element.fileName, element.asrDomain,element.length)"
                    matTooltip=" {{ 'home.seeTranscription' | translate }} " matTooltipPosition="left"></fa-icon>
                    <fa-icon *ngSwitchCase="'progress'"  class = "icon-disabled" [icon]="faEye"></fa-icon>
                    <fa-icon *ngSwitchCase="'New'"  class = "icon-disabled" [icon]="faEye"></fa-icon>
                </div>

              <div class = "icon-container">
                <fa-icon class = "icon-actions" (click) = "deleteAudioFile(element.audioId)" [icon]="faTrash"
                matTooltip="{{ 'home.delete' | translate }}" matTooltipPosition="left"></fa-icon>
              </div>
              <div class = "icon-container" [ngSwitch]="element.status">
                <fa-icon class = "icon-actions" *ngSwitchCase="'completed'" (click)="openModal('custom-modal-2', element)"   [icon]="faDownload" 
                matTooltip="{{ 'home.downloadTranscription' | translate }}" matTooltipPosition="left"></fa-icon>
                <fa-icon class = "icon-disabled"  *ngSwitchCase="'progress'" [icon]="faDownload"> </fa-icon>
                <fa-icon class = "icon-disabled"  *ngSwitchCase="'New'" [icon]="faDownload" ></fa-icon>
              </div>
            </div>
          </td>
        </ng-container>

        
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">     
          <mat-form-field appearance="outline" >
              <input (keyup) = "applyFilter($event.target)" matInput   placeholder="{{ 'home.searchFile' | translate }}">
          </mat-form-field>
        </tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
        

      </table>
    </div>
  <div style="height:10%;display:flex;align-items: center;">
    <!-- <input style="display:none"
    #uploader
      id="file" 
      type="file" 
      class="form-control"
      (change)="onFileChange($event)"> -->

      <!-- <button mat-raised-button color="success" (click)="uploader.click()"  class="btn btn-primary btn-block text-login"> -->
        <button mat-raised-button color="success" (click)="openModalUpload('custom-modal-3')"  class="btn btn-primary btn-block text-login">
        
        <mat-icon> file_upload</mat-icon>

          <span >{{ 'home.upload' | translate }}</span>
    </button>
    <!-- <span *ngIf="uploadInProgress" style="color:#0f8b99;">{{ 'home.uploading' | translate }} </span> -->
    
    
    </div>
    



</div>

<div class="mat-elevation-z8 table-button-container table-files" style="margin-top: 0;">


</div>


  <request-modal id="custom-modal-1" class="custom-modal">
    <div style="display: grid;  justify-content: right;"><fa-icon style="transform: scale(2); color: #0f8b99;  top: -32px;  right: -32px;position: relative;" [icon]="faCircleX" (click)="closeModal('custom-modal-1');"></fa-icon></div>
    <h1>{{ 'home.requestTranscription' | translate }}</h1>
    <h2>{{crtRequestAudioFile.fileName}}</h2>
    <mat-form-field appearance="outline" class="modal-form">
      <mat-label>{{'home.selectAsrDomain' | translate }}</mat-label>
      
      <mat-select [(ngModel)]="selectedAsrDomain">
        <mat-option *ngFor="let element of asrDomainList" [value]="element">
          {{element['name']}}
        </mat-option>
      </mat-select>
    </mat-form-field>
   <div style="display: grid;  justify-content: center;">
    <button mat-raised-button color="success" (click)="sendRequest('custom-modal-1');" class="btn btn-primary btn-block text-login" >
      <fa-icon class = "icon-actions" [icon]="faFolder"></fa-icon>
      <span style="padding-left: 10px;">{{ 'home.requestTranscription' | translate }}</span>
    </button>
    </div>
  </request-modal>

  <request-modal id="custom-modal-2" class="custom-modal">
    <div style="display: grid;  justify-content: right;"><fa-icon style="transform: scale(2); color: #0f8b99;  top: -32px;  right: -32px;position: relative;" [icon]="faCircleX" (click)="closeModal('custom-modal-2');"></fa-icon></div>
    <h1>{{'home.downloadTranscription' | translate }}</h1>
    <h2>{{crtRequestAudioFile.fileName}}</h2>
    <mat-form-field appearance="outline" class="modal-form">
      <mat-label>{{'home.selectFileFormat' | translate }}</mat-label>
      
      <mat-select [(ngModel)]="selectedDownloadFormat">
        <mat-option *ngFor="let element of downloadFormatList" [value]="element">
          {{element}}
        </mat-option>
      </mat-select>
    </mat-form-field>
   <div style="display: grid;  justify-content: center;">
    <button mat-raised-button color="success" (click)="downloadTranscriptionFile('custom-modal-2', crtRequestAudioFile.transcriptionJobId,crtRequestAudioFile.fileName, selectedDownloadFormat)" class="btn btn-primary btn-block text-login" >
      <fa-icon *ngIf="selectedDownloadFormat=='docx'" class = "icon-actions" [icon]="faDocumentWord"></fa-icon>
      <fa-icon *ngIf="selectedDownloadFormat=='json'" class = "icon-actions" [icon]="faDocumentCode"></fa-icon>
      <fa-icon *ngIf="selectedDownloadFormat=='srt'" class = "icon-actions" [icon]="faDocumentSubtitle"></fa-icon>
      <span style="padding-left: 10px;">{{ 'home.download' | translate }}</span>
    </button>
    </div>
  </request-modal>

  <request-modal id="custom-modal-3" class="custom-modal" style="text-align: center;  height: 40vw;  min-width: 60vw;">

    <div style="display: grid;  justify-content: right;"><fa-icon style="transform: scale(2); color: #0f8b99;  top: -32px;  right: -32px;position: relative;" [icon]="faCircleX" (click)="closeModal('custom-modal-3');"></fa-icon></div>
    
   <div style="display: flex;  justify-content: center; position:relative; height: 50vh; min-width: 60vw; ">
    <div *ngIf="currentUploadFiles.length==0" style="position: absolute; width: 100%; height: 100%; border-color: #0f8b99; border-style: dashed; border-radius: 20px; ">

      <input title=" " type="file" style="opacity: 0;width: 100%; height: 100%; position:absolute; left:0;z-index: 500;" #fileDropRef id="fileDropRef" multiple (change)="onUploadFilesChange($event)" />
      
      <div style="margin: 0; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <svg xmlns="http://www.w3.org/2000/svg" width="63" height="64" viewBox="0 0 63 64">
              <g fill="#0f8b99" fill-rule="nonzero">
                <path
                  d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z" />
                <path
                  d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z" />
                <path
                  d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z" />
              </g>
            </svg>
            <h3>Drag and drop files here</h3>
            <h3>or</h3>
            <label for="fileDropRef">Browse for files</label>
      </div>
    </div> 

    <div *ngIf="currentUploadFiles.length>0" style="position: relative; width: 100%; height: 100%; border-color: #0f8b99; border-style: dashed; border-radius: 20px; min-width: 20rem;">

      <input title=" " type="file" style="opacity: 0;width: 100%; height: 100%; position:absolute; left:0; z-index: 500;"  #fileDropRef id="fileDropRef" multiple (change)="onUploadFilesChange($event)" />
      
      <div style="margin: 0; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <svg xmlns="http://www.w3.org/2000/svg" width="63" height="64" viewBox="0 0 63 64">
              <g fill="#0f8b99" fill-rule="nonzero">
                <path
                  d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z" />
                <path
                  d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z" />
                <path
                  d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z" />
              </g>
            </svg>
            <h3>Drag and drop file here</h3>
            <h3>or</h3>
            <label for="fileDropRef">Browse for file</label>
      </div>

    </div>
    <div style=" padding-left: 50px;">
      <table >

        <tbody style="overflow-y: auto; display: block;  height: 50vh;  overflow-x: hidden; width:32rem">
          <tr *ngFor="let element of currentUploadFiles" >
            <!-- <td> <fa-icon style="transform: scale(2); color: crimson; padding-right: 10px;" [icon]="faCircleX" (click)="removeFile(element)"></fa-icon></td><td style="text-align:left">{{element['name']}}</td> -->

            <mat-card style="width: 30rem;margin: 5px;">
              <fa-icon style="font-size: 20px;  color: crimson; position: absolute; right: 5px; top: 5px;" [icon]="faCircleX" (click)="removeFile(element)"></fa-icon>
              <mat-card-header >
                <fa-icon style="font-size: 40px;color: #0f8b99;" *ngIf="element.file.type.includes('video')" mat-card-avatar [icon]="faFileVideo" ></fa-icon>
                <fa-icon style="font-size: 40px;color: #0f8b99;" *ngIf="element.file.type.includes('audio')" mat-card-avatar [icon]="faFileAudio" ></fa-icon>
                <mat-card-title style="font-size: 16px;overflow-wrap: anywhere; text-align: left;">{{element.file['name']}}</mat-card-title>
                <mat-card-subtitle><mat-progress-bar  style="width: 23rem;" mode="determinate" [value]="uploadProgress.get(element.uploadId)"></mat-progress-bar></mat-card-subtitle> 
              </mat-card-header>
              <mat-card-content>
                
              </mat-card-content>
            </mat-card>
        </tr>
      </tbody>
      </table>
    
<!--         <button *ngIf="currentUploadFiles.length>0" mat-raised-button color="success" (click)="sendRequest('custom-modal-3');" class="btn btn-primary btn-block text-login" >
          <mat-icon> file_upload</mat-icon>
          <span >{{ 'home.upload' | translate }}</span>
        </button> -->
</div>


  </div>


    
  </request-modal>

  <p-toast position="top-right" key="download"   >
    <ng-template let-message pTemplate="message" >
        <div class="flex flex-column" style="flex: 1; display: contents" >
        <span style="margin: 0.5rem 0 0 0;" class="p-toast-message-icon ng-tns-c3971568230-18 ng-star-inserted"><i class="pi pi-download" style="font-size: 2rem;"></i> </span>
        <div class="p-toast-message-text ng-tns-c3971568230-18 ng-star-inserted" style="">
          <div class="p-toast-detail ng-tns-c3971568230-18">{{message.summary}} </div>
          <div class="p-toast-detail ng-tns-c3971568230-18">
            <mat-progress-bar style="width:100%" mode="determinate" [value]="downloadProgress.get(message.detail)"></mat-progress-bar>
          </div>
        </div>
        <button type="button" (click)="onRejectDownload(message.detail)" pripple="" class="p-ripple p-element p-toast-icon-close p-link ng-tns-c3971568230-18 ng-star-inserted" style=""><i class="p-element p-icon-wrapper ng-tns-c3971568230-18 ng-star-inserted"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="p-icon p-toast-icon-close-icon" aria-hidden="true"><path d="M8.01186 7.00933L12.27 2.75116C12.341 2.68501 12.398 2.60524 12.4375 2.51661C12.4769 2.42798 12.4982 2.3323 12.4999 2.23529C12.5016 2.13827 12.4838 2.0419 12.4474 1.95194C12.4111 1.86197 12.357 1.78024 12.2884 1.71163C12.2198 1.64302 12.138 1.58893 12.0481 1.55259C11.9581 1.51625 11.8617 1.4984 11.7647 1.50011C11.6677 1.50182 11.572 1.52306 11.4834 1.56255C11.3948 1.60204 11.315 1.65898 11.2488 1.72997L6.99067 5.98814L2.7325 1.72997C2.59553 1.60234 2.41437 1.53286 2.22718 1.53616C2.03999 1.53946 1.8614 1.61529 1.72901 1.74767C1.59663 1.88006 1.5208 2.05865 1.5175 2.24584C1.5142 2.43303 1.58368 2.61419 1.71131 2.75116L5.96948 7.00933L1.71131 11.2675C1.576 11.403 1.5 11.5866 1.5 11.7781C1.5 11.9696 1.576 12.1532 1.71131 12.2887C1.84679 12.424 2.03043 12.5 2.2219 12.5C2.41338 12.5 2.59702 12.424 2.7325 12.2887L6.99067 8.03052L11.2488 12.2887C11.3843 12.424 11.568 12.5 11.7594 12.5C11.9509 12.5 12.1346 12.424 12.27 12.2887C12.4053 12.1532 12.4813 11.9696 12.4813 11.7781C12.4813 11.5866 12.4053 11.403 12.27 11.2675L8.01186 7.00933Z" fill="currentColor"></path></svg></i><!----><span class="p-ink ng-tns-c3971568230-18"></span></button>
        
          <!-- <i class="pi pi-download" style="font-size: 3rem; padding-right: 20px;"></i> -->
            <!-- <div  class="text-center" style="width:80%">
                <h4 style="text-align: left;">{{message.summary}}</h4>
                <mat-progress-bar style="width:100%" mode="determinate" [value]="downloadProgress.get(message.detail)"></mat-progress-bar>
            </div> -->
            <!-- <i class="pi pi-times-circle" style="font-size: 2rem; padding-left: 20px;width:20%" (click)="onRejectDownload(message.detail)"></i> -->
        
            
          </div>
    </ng-template>
    </p-toast>


</div>
</body>
