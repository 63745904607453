import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenStorageService } from '../_services/token-storage.service';
import { AudioFile } from '../_models/audio-file.model';
import api_path from '../../api-path-config'

@Injectable({
  providedIn: 'root'
})
export class AudioTableService {
  constructor(    
    private http: HttpClient,
    private tokenStorage: TokenStorageService
    ) { }

    getAudioTable(): any{
      let token = this.tokenStorage.getToken();
      if(token !== null) {
        return this.http.get(api_path+'/audio',{headers:new HttpHeaders({   'authorization' : token}), observe: 'response'});
      }
    }

    getAudioFile(audioId: number): any{
      let token = this.tokenStorage.getToken();
      if(token !== null) {
        const httpOptions = {
          headers: new HttpHeaders({   'authorization' : token})
        }; 

        return this.http.get(
          api_path+'/mediastream/' + audioId + '/' + token, 
          {  
              responseType: 'blob',
              reportProgress: true,
              observe: 'events' 
          }
        ); 

        //return this.http.get(api_path+'/mediastream/' + audioId + '/' + token,{ observe: 'response',reportProgress: true, responseType: 'blob'});
      }
    }

    getVideoFile(audioId: number): any{
      let token = this.tokenStorage.getToken();
      if(token !== null) {
        const httpOptions = {
          headers: new HttpHeaders({   'authorization' : token})
        }; 

      return this.http.head(api_path+'/mediastream/' + audioId+'/' + token,{ observe: 'response'});
      }
    }

    checkSwVideoFile(audioId: number): any{
      let token = this.tokenStorage.getToken();
      return this.http.head(api_path+'/mediastream/' + audioId+'/' + token,{observe: 'response'});
    }

    getAudioTranscription(jobId: number,format: string): any{
      let token = this.tokenStorage.getToken();
      if(token !== null) {
        return this.http.get(api_path+'/transcriptions/' + jobId + '/' + format,{headers:new HttpHeaders({   'authorization' : token}), observe: 'response', responseType: 'blob'});
      }
    }

    deleteAudioFile(audioId: number): any{
      let token = this.tokenStorage.getToken();
      if(token !== null) {
        return this.http.delete(api_path+'/audio/' + audioId,{headers:new HttpHeaders({   'authorization' : token}), observe: 'response'});
      }
    }

    uploadAudioFile(file: any): any {     
      let token = this.tokenStorage.getToken();
      if(token !== null) {
        const httpOptions = {
          headers: new HttpHeaders({   'authorization' : token})
        }; 
        return this.http.post(api_path+'/audio',file, httpOptions);
      }
    }

  async uploadMediaFileChunk(filename, uploadId, chunk, offset,limit,filesize ) {
      let token = this.tokenStorage.getToken();
      if(token !== null) {
        const httpOptions = {
          headers: new HttpHeaders({   'authorization' : token,"X-File-Name": filename, "X-Request-ID": uploadId,"Content-Range": "bytes " + offset + "-"+ limit+ "/" + filesize})
        }; 
        return this.http.post(api_path+'/upload',chunk, httpOptions).toPromise();
      }
      return this.http.post(api_path+'/upload', chunk).toPromise();

   }
}
