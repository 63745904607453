<p-toast></p-toast>


<div id="app" style="height:100%;min-width:1000px; min-height: 900px;" click="checkForValidToken()">
  <nav class="navbar navbar-expand navbar-dark bg-dark" style="min-height:10%">
    <a href="/"  rel="home"><img class="custom-logo-link" [src]="'assets/images/zevo_logo.png'" sizes="(max-width: 2048px) 100vw, 2048px"></a>
    <span class="ml-auto p-2" *ngIf="!isLoggedIn">
      <span class="sel-txt-lang" >{{ 'selectLanguage' | translate }}</span>
      <img class="custom-logo-lang" (click)="translateRO()" [src]="'assets/images/RO.svg'" alt="RO">
      <img class="custom-logo-lang" (click)="translateEN()" [src]="'assets/images/US.svg'" alt="EN">
    </span>
    <ul class="navbar-nav ml-auto" style="padding-right:2%" *ngIf="isLoggedIn">
      <li class="nav-item">
        <a href="/profile" class="nav-link" routerLink="profile">{{ username }}</a>
      </li>
      <li class="nav-item">
        <img class = "custom-avatar" [src]="'assets/images/profile_avatar.jpg'" >
      </li>
      <li class="nav-item" style="padding-left:5px; padding-right:15px;">

            <span class = "text-login">{{currentUser.firstName}} {{currentUser.lastName}}</span>
            <br>
            <span class = "text-login">{{currentUser.userEmail}}</span>

        <!--  <a href class="nav-link" (click)="logout()">LogOut</a> -->
      </li>
      <li class="nav-item">
      <button mat-button [matMenuTriggerFor]="menu" aria-label="Menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" >
        <button mat-menu-item routerLink="/profile"> 
          <mat-icon class="text-login">person</mat-icon>
          <span class="text-login">{{ 'profile' | translate }}</span>
        </button>
        <button mat-menu-item (click)="changeLang()">
          <img *ngIf='crt_lang == "en"' class="mat-icon" [src]="'assets/images/RO.svg'"  alt="RO">
          <img *ngIf='crt_lang == "ro"' class="mat-icon" [src]="'assets/images/US.svg'" alt="EN">
          <span class="text-login">{{ 'lang' | translate }}</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon class="text-login">logout</mat-icon>
          <span class="text-login">{{ 'logout' | translate }}</span>
        </button>
      </mat-menu>
      </li>
    </ul>
  </nav>



  
  <!-- <div style="height:80%;padding-top: 2%; padding-bottom: 2%; "> -->
    <div style="height:80%;">
    <router-outlet></router-outlet>
  </div>
  <nav class="navbar navbar-expand navbar-dark bg-dark bottomNavbar" style="min-height:7%;z-index: 999;">
    <span class="sel-txt-zevo">©Zevo Technologies S.R.L. <br>v{{versionCrt}}</span>
    
    <ul class="navbar-nav ml-auto contact" >
      
      <button mat-raised-button color="success" class="btn btn-primary btn-block text-login" onClick="window.open('https://zevo-tech.com/contact/')" >
        <mat-icon>error</mat-icon>
        <a> {{ 'contactSupport' | translate }}</a>
      </button>
    </ul>
  </nav>
</div>