<div class="col-md-12 signup-container ">
  <div class="card card-container">
    <mat-card>
      <mat-card-content>
        <h2 class="headertekst">{{ 'login.titleSignUp' | translate }}</h2>
        <form
          name="form"
          (ngSubmit)="f.form.valid && onSubmit()"
          #f="ngForm"
          novalidate
          class="format-form"
        >

        <div class="form-group email">
          <mat-form-field appearance="outline" > 
          
          <mat-label>{{ 'login.firstName' | translate }}</mat-label>
        <input matInput
          type="text"
          name="firstName"
          [(ngModel)]="form.firstName"
          required
          #firstName="ngModel" 
          placeholder="{{ 'login.firstNameIntr' | translate }}"
          required
          />
          
          <mat-error *ngIf="firstName.hasError('required') && f.submitted">
            {{ 'login.invalidName' | translate }}
          </mat-error>
        </mat-form-field>
        </div>

        <div class="form-group email">
          <mat-form-field appearance="outline" > 
          
          <mat-label>{{ 'login.lastName' | translate }}</mat-label>
        <input matInput
          type="text"
          name="lastName"
          [(ngModel)]="form.lastName"
          required
          #lastName="ngModel" 
          placeholder="{{ 'login.lastNameIntr' | translate }}"
          required
          />
          
          <mat-error *ngIf="lastName.hasError('required') && f.submitted">
            {{ 'login.invalidLastName' | translate }}
          </mat-error>
        </mat-form-field>
        </div>
        

          <div class="form-group email">
            <mat-form-field appearance="outline" > 
            
            <mat-label>{{ 'login.email' | translate }}</mat-label>
          <input matInput
            type="email"
            [formControl]="emailFormControl" 
            [errorStateMatcher]="matcher"
            name="username"
            [(ngModel)]="form.username"
            required
            
            placeholder="{{ 'login.intrEmail' | translate }}"
            required
            />
            
            <mat-error *ngIf="(emailFormControl.hasError('email')&& f.submitted) || (emailFormControl.hasError('required') && f.submitted)">
              {{ 'login.invalidEmail' | translate }}
            </mat-error>
          </mat-form-field>
          </div>
          <div class="form-group">
            
            
            <mat-form-field appearance="outline">
              <mat-label>{{ 'login.password' | translate }}</mat-label>
            <input matInput
            type="password"
            name="password"
            [(ngModel)]="form.password"
            
            required
            minlength="6"
            #password="ngModel" 
            placeholder="{{ 'login.intrPassword' | translate }}"
            required
            />
            <mat-error *ngIf="password.hasError('required') && f.submitted" >{{ 'login.passwordRequired' | translate }}</mat-error>
            <mat-error *ngIf="password.hasError('minlength') && f.submitted" >{{ 'login.passwordShort' | translate }}</mat-error>
          </mat-form-field>
            
          </div>

          <div class="form-group">
            
            
            <mat-form-field appearance="outline">
              <mat-label>{{ 'login.retypePassword' | translate }}</mat-label>
            <input matInput
            type="password"
            name="retypePassword"
            [(ngModel)]="form.retypePassword"
            
            required
            #retypePassword="ngModel" 
            placeholder="{{ 'login.intrRetypePassword' | translate }}"
            required
            />
            <mat-error *ngIf="retypePassword.hasError('required') && f.submitted" >{{ 'login.intrRetypePassword' | translate }}</mat-error>
            
          </mat-form-field>
            
          </div>
          <div class="form-group terms">
            
          <mat-label >
            {{ 'login.termsP1' | translate }} <a href> {{ 'login.privacyPolicy' | translate }} </a> {{ 'login.termsP2' | translate }} <a href>{{ 'login.termsService' | translate }}</a>.
          </mat-label>
          
        </div>
        <div>
          <mat-checkbox (change)="changeAgree()" class="example-margin" style="padding-bottom: 10px;"> {{ 'login.agree' | translate }}</mat-checkbox>
        </div>
          <div class="form-group">
            <button mat-raised-button color="success" class="btn btn-primary btn-block text-login" [disabled]="!checkBox"  >
              {{ 'login.registerTxtButon' | translate }}
            </button>
          </div>
          <div class="form-group unauth">
            <mat-label> 
            <mat-error  *ngIf="f.submitted && isPasswordMissmatch" >
              <h4 class="headertekst">{{ 'login.passwordMissmatch' | translate }}</h4>
              
            </mat-error>
          </mat-label> 

          <mat-label> 
            <mat-error  *ngIf="emailAlreadyInUse" >
              <h4 class="headertekst">{{ 'login.emailAlreadyInUse' | translate }}</h4>
              
            </mat-error>
          </mat-label> 
          

          <mat-label *ngIf="isAccountCreated"> 
            <h4 class="headertekst">{{ 'login.signUpSuccess' | translate }}</h4>
          </mat-label>
            
          </div>
        </form>

        </mat-card-content>
      </mat-card>
  </div>
</div>