import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { NullTemplateVisitor } from '@angular/compiler';
import {MessageService} from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  form: any = {
    firstName:null,
    lastName:null,
    username: null,
    password: null,
    retypePassword:null
  };
  isPasswordMissmatch =false;
  isSuccessful = false;
  emailAlreadyInUse= false;
  isSignUpFailed = false;
  isAccountCreated = false;
  isAccountFailed = false;
  checkBox = false;
  errorMessage = '';
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  matcher = new MyErrorStateMatcher();
  constructor( public authService: AuthService,private messageService: MessageService, private translate: TranslateService,private primengConfig: PrimeNGConfig,private router: Router) { }
  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }
  onSubmit(): void {
    const { firstName , lastName, username, password,retypePassword } = this.form;
    if (retypePassword==password){
      //
      this.isPasswordMissmatch = false;
    this.authService.register(firstName , lastName, username,password).subscribe({
      next: (data) => {
        this.emailAlreadyInUse = false;
        this.isAccountFailed = false;
        this.isAccountCreated = true;
        this.translate.get('login.accountCreated').subscribe((res: string) => { let message = res.split(":"); this.messageService.add({ severity:'success',sticky:true, summary: message[0], detail: message[1]});});
        setTimeout(() => {
          setTimeout(() => {
            this.router.navigateByUrl("/");
          });
        }, 3400);

        return data.body;
      },
      error: err => {
        if (err.status == 500){
          this.emailAlreadyInUse = true;
        }
        this.isAccountFailed = true;
        this.isAccountCreated = false;
        console.log(err)
        return null;
      }
    });

  }else{
    this.isPasswordMissmatch = true;
  }
  }
  changeAgree():any{
    this.checkBox = !this.checkBox;
  }
}