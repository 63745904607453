import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';
import {Md5} from 'ts-md5/dist/md5';
import api_path from '../../api-path-config'



const AUTH_API = 'http://localhost:8080/api/auth/';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUser : any;
  isLoginFailed = false;
  isAccountFailed = false;
  isAccountCreated = false;
  emailAlreadyInUse = false;
  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorageService,
    private router: Router
    ) { }

  login(username: string, password: string): any {
    const md5 = new Md5();
    let loginJson = {
      "userEmail": username,
      "password": md5.appendStr(password).end()
    };
    this.http.post(api_path+'/users/login', loginJson, { observe: 'response', responseType: 'text'}).subscribe({
      next: (data) => {
        this.tokenStorage.saveToken(data.body);
        this.tokenStorage.saveUser(username);
        this.reloadPage();
        return data.body;
      },
      error: err => {
        this.isLoginFailed = true;
        console.log(err)
        return null;
      }
    });
  }

  register(firstName: string,lastName: string, username: string, password: string): any {

    const md5 = new Md5();
    let registerJson = {
      "firstName": firstName, 
      "lastName": lastName, 
      "userEmail": username,
      "password": md5.appendStr(password).end()
    };

    return this.http.post(api_path+'/users/register', registerJson, { observe: 'response', responseType: 'text'})
  }

  updateInfoPassword(username: string, password: string): any {
    let token = this.tokenStorage.getToken();
    if(token !== null) {
    const httpOptions = {
        headers: new HttpHeaders({ 'authorization' : token})
      }; 

    const md5 = new Md5();
    let updateJson = {
      "userEmail": username, 
      "password": md5.appendStr(password).end(), 
    };

    this.http.put(api_path+'/users/info', updateJson, httpOptions).subscribe({
      next: (data) => {
        this.reloadPage();
        return data;
      },
      error: err => {
        if (err.status == 500){
          this.emailAlreadyInUse = true;
        }
        console.log(err)
        return null;
      }
    });
  };
  }

  updateInfoEmail(username: string, password: string): any {
    let token = this.tokenStorage.getToken();
    if(token !== null) {
    const httpOptions = {
        headers: new HttpHeaders({ 'authorization' : token})
      }; 
      console.log(password);
    const md5 = new Md5();
    let updateJson = {
      "userEmail": username, 
      "password": password,
    };

    this.http.put(api_path+'/users/info', updateJson, httpOptions).subscribe({
      next: (data) => {
        this.reloadPage();
        return data;
      },
      error: err => {
        if (err.status == 500){
          this.emailAlreadyInUse = true;
        }
        console.log(err)
        return null;
      }
    });
  };
  }

  info(): any {
    let token = this.tokenStorage.getToken();
    if(token !== null) {
      const httpOptions = {
        headers: new HttpHeaders({ 'authorization' : token})
      }; 
      return this.http.get(api_path+'/users/info',httpOptions);

  }
  }

  reloadPage(): void {
    this.router.navigateByUrl('/').then(() => {
      window.location.reload();
    });
  }

  get isLoggedIn(): boolean {
    const user = this.tokenStorage.getToken();
    return (user !== null) ? true : false;
  }
}
