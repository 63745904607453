<div class="col-md-12 login-container">
    <div class="card card-container">
      <mat-card>
        <mat-card-content>
          <h2 class="headertekst">{{ 'login.title' | translate }}</h2>
          <form
            *ngIf="!isLoggedIn"
            name="form"
            (ngSubmit)="f.form.valid && onSubmit()"
            #f="ngForm"
            novalidate
            class="format-form"
          >
            <div class="form-group email">
              <mat-form-field appearance="outline" > 
              
              <mat-label>{{ 'login.email' | translate }}</mat-label>
            <input matInput
              type="email"
              [formControl]="emailFormControl" 
              [errorStateMatcher]="matcher"
              name="username"
              [(ngModel)]="form.username"
              required
              
              placeholder="{{ 'login.intrEmail' | translate }}"
              required
              />
              
              <mat-error *ngIf="(emailFormControl.hasError('email')&& f.submitted) || (emailFormControl.hasError('required') && f.submitted)">
                {{ 'login.invalidEmail' | translate }}
              </mat-error>
            </mat-form-field>
            </div>
            <div class="form-group">
              
              
              <mat-form-field appearance="outline">
                <mat-label>{{ 'login.password' | translate }}</mat-label>
              <input matInput
              type="password"
              name="password"
              [(ngModel)]="form.password"
              
              required
              minlength="5"
              #password="ngModel" 
              placeholder="{{ 'login.intrPassword' | translate }}"
              required
              />
              <mat-error *ngIf="password.hasError('required') && f.submitted" >{{ 'login.passwordRequired' | translate }}</mat-error>
              <mat-error *ngIf="password.hasError('minlength') && f.submitted" >{{ 'login.passwordShort' | translate }}</mat-error>
            </mat-form-field>
              
            </div>
            <div class="form-group forgot">
              <a href class="nav-link forgot" >{{ 'login.passwordForgot' | translate }}</a>
            </div>
            <div class="form-group">
              <button mat-raised-button color="success" class="btn btn-primary btn-block text-login">
                {{ 'login.loginTxtButon' | translate }}
              </button>
            </div>

            <div class="form-group unauth">
              <mat-label> 
                
              <mat-error  *ngIf="f.submitted && authService.isLoginFailed" >
                <h4 class="headertekst">{{ 'login.failedLogin' | translate }}</h4>
                
              </mat-error>
            </mat-label> 
              
            </div>
          </form>
          <mat-card-actions class="signup">
            {{ 'login.dontHaveAccount' | translate }} <a href routerLink="/register" routerLinkActive="active" class="nav-link forgot" >{{ 'login.signUp' | translate }} </a>
          </mat-card-actions>
          </mat-card-content>
        </mat-card>
      <div class="alert alert-success" *ngIf="isLoggedIn">
        Logged in as {{ username }}.
      </div>
    </div>
  </div>
