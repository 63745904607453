import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenStorageService } from '../_services/token-storage.service';
import api_path from '../../api-path-config'

@Injectable({
  providedIn: 'root'
})
export class TranscriptionsService {

  constructor(    
    private http: HttpClient,
    private tokenStorage: TokenStorageService
    ) { }

    getAsrDomains(): any{
      let token = this.tokenStorage.getToken();
      if(token !== null) {
        return this.http.get(api_path+'/transcriptions/domains',{headers:new HttpHeaders({   'authorization' : token}), observe: 'response'});
      }
    }

    postAutomaticSpeechRequest(asrDomainId: number, postProcessing: boolean, audioId: number): any{
      let token = this.tokenStorage.getToken();
      let autoSpeechRequestPayload = {
        "asr_domain_id": asrDomainId,
        "post_processing": postProcessing
      };
      if(token !== null) {
        return this.http.post(api_path+'/transcriptions/' + audioId, autoSpeechRequestPayload, { headers:new HttpHeaders({   'authorization' : token}),observe: 'response'});
      }
    }

    getAllTranscriptionJobs(): any{
      let token = this.tokenStorage.getToken();
      if(token !== null) {
        return this.http.get(api_path+'/transcriptions/jobs',{headers:new HttpHeaders({   'authorization' : token}), observe: 'response'});
      }
    }


    getTranscriptionJob(jobId: number): any{
      let token = this.tokenStorage.getToken();
      if(token !== null) {
        return this.http.get(api_path+'/transcriptions/' + jobId +'/json',{headers:new HttpHeaders({   'authorization' : token}), observe: 'response'});
      }
    }

    postTranscription(jobId: number,transcription: any): any {     
      let token = this.tokenStorage.getToken();

      if(token !== null) {
        return this.http.post(api_path+'/correction/'+ jobId ,transcription,{headers:new HttpHeaders({ 'authorization' : token,  'content-type' : "application/json"}),observe: 'response',responseType:"text"})
      }
    }
}
