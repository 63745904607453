import { Component,Pipe, PipeTransform } from '@angular/core';
import { TokenStorageService } from './_services/token-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './_services/auth.service';
import { User } from './_models/user.model';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {MessageService} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import packageInfo from 'package.json'

@Pipe({ name: "safeHtml" })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Pipe({ name: "parseTimeFormat" })
export class ParseTimeFormatPipe implements PipeTransform {
  constructor() {}

  transform(value) {
    let nr_seconds_total = Math.ceil(value);
    let nr_hours = Math.floor(nr_seconds_total/3600);
    nr_seconds_total = nr_seconds_total-nr_hours*3600;
    let nr_minutes = Math.floor(nr_seconds_total/60);
    nr_seconds_total = nr_seconds_total-nr_minutes*60;
    let nr_minutes_string = nr_minutes.toString();
    let nr_seconds_string = nr_seconds_total.toString();
    if (nr_minutes_string.length<2) {nr_minutes_string = "0"+ nr_minutes_string;}
    if (nr_seconds_string.length<2) {nr_seconds_string = "0"+ nr_seconds_string;}
    return nr_hours+"h "+ nr_minutes_string+ "m " + nr_seconds_string+"s";
   
  }
}

@Pipe({ name: "parseTimeFormatPlayer" })
export class ParseTimeFormatPlayerPipe implements PipeTransform {
  constructor() {}

  transform(value) {
    let nr_seconds_total = Math.ceil(value);
    let nr_hours = Math.floor(nr_seconds_total/3600);
    nr_seconds_total = nr_seconds_total-nr_hours*3600;
    let nr_minutes = Math.floor(nr_seconds_total/60);
    nr_seconds_total = nr_seconds_total-nr_minutes*60;
    let nr_minutes_string = nr_minutes.toString();
    let nr_seconds_string = nr_seconds_total.toString();
    if (nr_minutes_string.length<2) {nr_minutes_string = "0"+ nr_minutes_string;}
    if (nr_seconds_string.length<2) {nr_seconds_string = "0"+ nr_seconds_string;}
    if (nr_hours>0){
      return nr_hours+":"+ nr_minutes_string+ ":" + nr_seconds_string;
    }else{
      return nr_minutes_string+ ":" + nr_seconds_string;
    }
   
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService]
})
export class AppComponent {
  versionCrt=packageInfo.version;
  currentUser:any ;
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  crt_lang = "en";
  username?: string;
  constructor( private tokenStorageService: TokenStorageService,public translate: TranslateService, public authService: AuthService, public router:Router,private messageService: MessageService, private primengConfig: PrimeNGConfig) { 
    translate.addLangs(['en','ro']);
    translate.setDefaultLang('ro');
    translate.use('ro');
    this.crt_lang = "ro";
  }
  ngOnInit(): void {
    let data_init ={
      'userId' : null,
      'firstName' : null,
     'lastName' : null,
      'userEmail' : null,
      'diarizationSeconds' : null,
     'speechToTextSeconds' : null,
      'keywordsSeconds' : null,
      'keywordsCount' : null,
      'diacriticsRestWords' : null,
      'allowLogin' : null,
      'createdDate' : null
    }
    this.currentUser = new User(data_init);
    console.log("Reloaded")
    this.primengConfig.ripple = true;

    this.isLoggedIn = !!this.tokenStorageService.getToken();
    if (this.tokenStorageService.getLang()){
      this.crt_lang = this.tokenStorageService.getLang();
      this.translate.setDefaultLang(this.crt_lang);
      this.translate.use(this.crt_lang);
      
      
    }else{
      this.translate.use('ro');
      this.crt_lang = "ro";
    }
    
    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      
      this.authService.info().subscribe({
        next: (dataFile: { body: any; }) => {
          this.currentUser = new User(dataFile);
        },
        error: (err: any) => {
          this.tokenStorageService.signOut();
          console.log(err);
          return null;
        }
      });
      

      this.username = user.username;
    }

  }

  
  logout(): void {
    this.tokenStorageService.signOut();
    this.router.navigate(['/']);
    window.location.reload()
   
  }
  translateEN():void{
    this.translate.use('en');
    this.translate.setDefaultLang('en');
    this.crt_lang = "en";
    this.tokenStorageService.saveLang(this.crt_lang);
  }
  translateRO():void{
    this.translate.use('ro');
    this.translate.setDefaultLang('ro');
    this.crt_lang = "ro";
    this.tokenStorageService.saveLang(this.crt_lang);
  }
  changeLang():void{
    if (this.crt_lang == "en"){
      this.translateRO();
    }else{
      this.translateEN();
    }

  }

}