import { HttpClient,HttpEventType } from '@angular/common/http';
import { Component, OnInit, ViewChild,ViewContainerRef,ChangeDetectorRef } from '@angular/core';
import { AudioTableService } from '../_services/audio-table.service';
import { AudioFile } from '../_models/audio-file.model';
import { Job } from '../_models/job.model';
import { faEye, faTrash, faDownload, faFolderPlus,faFileWord,faFileCode, faCircleXmark,faFileAudio,faFileVideo,faFileInvoice} from '@fortawesome/free-solid-svg-icons';
import { TranscriptionsService } from '../_services/transcriptions.service';
import { saveAs } from 'file-saver';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { RequestModalService } from '../_shared/request-modal.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import {TokenStorageService} from '../_services/token-storage.service';
import {MessageService} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../_services/auth.service';
import { Toast } from 'primeng/toast';
import { Observable, Subscription } from 'rxjs';

interface currentUploadFile {
  file: File;
  uploadId: string;
}


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})



export class HomeComponent implements OnInit {
  currentUploadFiles:currentUploadFile[];
  uploadProgress:Map<string, number>;
  downloadProgress:Map<string, number>;
  downloadEvents:Map<string, Subscription>;
  downloadFormatList = ['docx','json','srt'];
  selectedDownloadFormat:string;
  faDocumentWord = faFileWord;
  faDocumentCode = faFileCode;
  faDocumentSubtitle = faFileInvoice;
  faEye = faEye;
  faTrash = faTrash;
  faDownload = faDownload;
  faFolder = faFolderPlus;
  faCircleX = faCircleXmark;
  faFileAudio = faFileAudio;
  faFileVideo = faFileVideo;

  uploadInProgress = false;
  audioTable: AudioFile[];
  audioFiles:any;
  jobs: Job[];
  displayedColumns: string[] = ['fileName', 'length', 'createdDate', 'asrDomain', 'status', 'actions'];
  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
  bodyText: string;
  crtRequestAudioFile: AudioFile;
  asrDomainList: {}[];
  selectedAsrDomain: any = {};
  postProcessing: boolean = true;
  dataSource: any;
  crtFilter: any;
  checked_token_time:any ;
  constructor(
    public authService: AuthService,
    private audioTableService : AudioTableService,
    private translate: TranslateService,
    private messageService: MessageService, 
    private tokenStorage: TokenStorageService,
    private transcriptionsService: TranscriptionsService,
    private http: HttpClient,
    private requestModalService: RequestModalService,
    private _liveAnnouncer: LiveAnnouncer,
    private primengConfig: PrimeNGConfig,
    private router: Router,
    private cdr: ChangeDetectorRef
    ) { 
      this.audioTable = [];
      this.jobs = [];
      this.bodyText = '';
      this.crtRequestAudioFile = {};
      this.asrDomainList = [];
      this.currentUploadFiles = [];
      this.downloadProgress = new Map<string, number>();
      this.downloadEvents = new Map<string, Subscription>();
      this.uploadProgress = new Map<string, number>();
      
  }

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(Toast) toast: Toast;
  @ViewChild(Toast, { read: ViewContainerRef, static: true })
  toastRef: ViewContainerRef;

  clear(type: string) {
    this.toast.messages = this.toast.messages.filter(
      (x) => x.id !== type
    );
    const changeDetectorRef = this.toastRef.injector.get(ChangeDetectorRef);
    changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.selectedDownloadFormat = 'docx';
    this.audioTable = [];
    this.checked_token_time = new Date();
    this.transcriptionsService.getAsrDomains().subscribe({
      next: (dataAsrDomains: { body: any; }) => {
        let asrDomain =  dataAsrDomains.body[0].name;
        this.audioTableService.getAudioTable().subscribe({
          next: (data: { body: any; }) => {
            this.audioFiles = JSON.parse(JSON.stringify(data.body));
            
            this.transcriptionsService.getAllTranscriptionJobs().subscribe({
              next: (dataJobs: { body: any; }) => {
                this.jobs = JSON.parse(JSON.stringify(dataJobs.body));
                //console.log(dataJobs.body)
                for (var audioElement of this.audioFiles) {
                  let newFile = true;
                  for (var jobElement of this.jobs) { 

                    if(audioElement.audioId == jobElement.audioId) {
                      const tmpAudioJob :AudioFile ={
                      createdDate : jobElement.createdDate,
                      fileName : audioElement.fileName,
                      length : audioElement.length,
                      userId : audioElement.userId,
                      asrDomain : jobElement.asrDomainName,
                      //this.audioTable[k].asrDomainId = audioElement.;
                      status : jobElement.status,
                      transcriptionJobId : jobElement.jobId,
                      audioId : audioElement.audioId
                      }
                      newFile=false;
                      this.audioTable.push(tmpAudioJob);
                    }
                  }
                  
                  if(newFile){
                    
                    const tmpAudioJob :AudioFile ={
                    createdDate : '',
                    fileName : audioElement.fileName,
                    length : audioElement.length,
                    userId : audioElement.userId,
                    asrDomain : '',
                    //this.audioTable[k].asrDomainId = audioElement.;
                    status :'New',
                    transcriptionJobId : null,
                    audioId : audioElement.audioId
                    
                    }
                    this.audioTable.push(tmpAudioJob);
                  }

                }


                this.dataSource = new MatTableDataSource(this.audioTable);
                this.dataSource.sort = this.sort;
              },
              error: (err: any) => {
                console.log(err);
                this.jobs = [];
                return null;
              }
            });
          },
          error: (err: any) => {
            this.audioTable = [];
            console.log(err);
            return null;
          }
        });
      },
      error: (err: any) => {
        console.log(err);
        this.jobs = [];
        return null;
      }
    })
  }

  updateAudioFileStatus(audioId: number) {
    
    this.transcriptionsService.getAllTranscriptionJobs().subscribe({
      next: (dataJobs: { body: any; }) => {
        this.jobs = JSON.parse(JSON.stringify(dataJobs.body));
        for(var audioElement of this.audioTable) {
          if(audioId == audioElement.transcriptionJobId) {
            for (var jobElement of this.jobs) { 
              if(audioId == jobElement.jobId) {
                audioElement.asrDomain = jobElement.asrDomainName;
                audioElement.status = jobElement.status;
                audioElement.transcriptionJobId = jobElement.jobId;
                audioElement.createdDate = jobElement.createdDate;
              }
            }
          }
        }
      },
      error: (err: any) => {
        console.log(err);
        this.jobs = [];
        return null;
      }
    });
  }

  onRejectDownload(downloadId:string){
    this.downloadEvents.get(downloadId).unsubscribe();
    this.clear(downloadId);
    this.downloadEvents.delete(downloadId)
    this.downloadProgress.delete(downloadId);
  }

 downloadAudioFile(audioId: number, fileName: string){
    
    let downloadId = Math.random()+audioId+"_"+fileName;
    this.downloadProgress.set(downloadId,0);
    
    this.translate.get('home.downloadProgress').subscribe((res: string) => { this.messageService.add({ id:downloadId, key:"download", severity:'info', summary: res+" " + fileName, detail: downloadId, sticky:true, closable:false});});

    this.downloadEvents.set(downloadId,
    this.audioTableService.getAudioFile(audioId).subscribe(event =>{
  
      if (event.type === HttpEventType.DownloadProgress){
        this.downloadProgress.set(downloadId,Math.round(100 * event.loaded / event.total));
      }

      if (event.type === HttpEventType.Response) {
        this.clear(downloadId);
        const blob = new Blob([event.body], { 'type' : 'application/octet-stream' });
        saveAs(blob, fileName);
        this.downloadEvents.delete(downloadId)
        this.downloadProgress.delete(downloadId);
      }
    } 
    )
   )
  }

  downloadTranscriptionFile(id:string, jobId: number, fileName: string, format:string): void {

    this.audioTableService.getAudioTranscription(jobId,format).subscribe({
      next: (dataFile: { body: any; }) => {
        const blob = new Blob([dataFile.body], { 'type' : 'text/json' });
        fileName = fileName.replace('.',"_");
        fileName = fileName.concat('.'+format);
        saveAs(blob, fileName);
        this.closeModal(id);
        
      },
      error: (err: any) => {
        console.log(err);
        return null;
      }
    });
  }


  deleteAudioFile(audioId: number): void {
    let crtAudioTable: AudioFile[] = [];
    this.audioTableService.deleteAudioFile(audioId).subscribe({
      next: (dataFile: { body: any; }) => {
        this.audioTable.forEach((element,index)=>{
          if(element.audioId!=audioId)  {
            crtAudioTable.push(element);
          }else{
            this.translate.get('home.deleteFile').subscribe((res: string) => { this.messageService.clear(); let message = res.split(":");this.messageService.add({ severity:'warn', summary: message[0], detail: message[1]+element.fileName});});
          }
        });
        this.audioTable = crtAudioTable;
        this.dataSource = new MatTableDataSource(this.audioTable);
        this.dataSource.sort = this.sort;
       
        //this.applyFilter(this.crtFilter);
      },
      error: (err: any) => {
        console.log(err);
        return null;
      }
    });
  }

  onFileChange(event:any) {
    
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
      
      //this.uploadSubmit(file);
      event.target.value="";
    }
  }

  async uploadSubmit(file: any,uploadId:string){
    //this.uploadInProgress = true;
    // const formData = new FormData();
    // formData.append('audioFile', this.myForm.get('fileSource')?.value,this.myForm.get('fileName')?.value);
    // this.audioTableService.uploadAudioFile(formData).subscribe({
    //   next: (uploadedFile: { body: any; }) => {
    //     this.ngOnInit();
    //     this.uploadInProgress = false;
    //   },
    //   error: (err: any) => {
    //     this.uploadInProgress = false;
    //     console.log(err);
    //     alert(err.error.message);
    //     return null;
        
    //   }
    // })
    if (file.name.toLowerCase().includes(".m4a") || file.name.toLowerCase().includes(".mp4") || file.name.toLowerCase().includes(".mp3") || file.name.toLowerCase().includes(".wav")){
    //this.uploadInProgress = true;
    //this.uploadProgress = 0;
    let filename_parsed = file.name.normalize("NFD").replaceAll(/[^\x20-\x7E]/g, "").replaceAll(" ","_").replaceAll(/[&\/\\#,+()$~%'"”:*?<>{}]/g, "");
    this.uploadProgress.set(uploadId,0);
    let crtUploadFile = {"file":file,"uploadId":uploadId};
    this.currentUploadFiles.push(crtUploadFile);
    const chunkSize_max = 2000000;
    let errorMessage = "";
    let chunkSize = Math.round(file.size/5);
    if (chunkSize >chunkSize_max) chunkSize = chunkSize_max;
    let hasErrors = false;
    for( let offset = 0; offset < file.size; offset += chunkSize ){
      //console.log(this.uploadProgress.get(uploadId))
      if (this.uploadProgress.get(uploadId)>-1){
        this.uploadProgress.set(uploadId,(offset/file.size)*100);
        if ((offset + chunkSize)<file.size){
          const chunk = file.slice( offset, offset + chunkSize );
          const formData = new FormData();
          formData.append("file", chunk);
          try {
          await this.audioTableService.uploadMediaFileChunk(filename_parsed,uploadId, formData,offset,offset + chunkSize, file.size );
          } catch(error){
            console.log(error)
            hasErrors = true
            if(error.error.message){
            errorMessage = error.error.message;
            }else{
              errorMessage = error.message;
            }
            break
          }
        }else{
          const chunk = file.slice( offset, file.size );
          const formData = new FormData();
          formData.append("file", chunk);
          try {
          let data = await this.audioTableService.uploadMediaFileChunk(filename_parsed,uploadId, formData,offset,file.size, file.size );
          }catch(error){
            hasErrors = true
            if(error.error.message){
              errorMessage = error.error.message;
              }else{
                errorMessage = error.message;
              }
            break
          }
        }
      
        
      }
      else{
        this.uploadProgress.delete(uploadId);
        hasErrors = true;
        errorMessage = 'cancel'
      }
      //console.log(this.uploadProgress.get(uploadId))
    }
    if (hasErrors){
      if (errorMessage!='cancel'){
        this.translate.get('home.uploadFailed').subscribe((res: string) => { let message = res.split(":");this.messageService.add({ severity:'warn', summary: message[0], detail: crtUploadFile.file.name+" - "+message[1]+ ": "+errorMessage, sticky:true});});
      }
      this.removeFile(crtUploadFile);
      this.uploadProgress.delete(uploadId);

    }else{
      this.translate.get('home.uploadSuccess').subscribe((res: string) => { let message = res.split(":");this.messageService.add({ severity:'success', summary: message[0], detail: crtUploadFile.file.name+": "+message[1]});});
      this.removeFile(crtUploadFile);
      this.uploadProgress.delete(uploadId);
     
    }
    //this.uploadInProgress = false;
    this.ngOnInit()
    }else{
      this.translate.get('home.invalidFileType').subscribe((res: string) => { this.messageService.clear(); let message = res.split(":");this.messageService.add({ severity:'warn', summary: message[0], detail: message[1], sticky:true});});
    }

  }

  sendRequest(id: string) {
    //console.log("Request for audio:" + this.crtRequestAudioFile.fileName);
    //console.log("Selected Asr Domain:" + this.selectedAsrDomain['asrDomainId']);
    if(this.crtRequestAudioFile.audioId != undefined) {
      this.transcriptionsService.postAutomaticSpeechRequest(
        this.selectedAsrDomain['asrDomainId'], this.postProcessing, this.crtRequestAudioFile.audioId).subscribe({
        next: (response: { body: any; }) => {
          if (this.crtRequestAudioFile.transcriptionJobId == undefined){
            this.crtRequestAudioFile.transcriptionJobId = response.body["Job ID"];
            this.updateStatusAfterTrasncript(response.body["Job ID"]);
          }else{
            if (response.body.status==undefined){
            const tmpAudioJob :AudioFile ={
              createdDate : '',
              fileName : this.crtRequestAudioFile.fileName,
              length : this.crtRequestAudioFile.length,
              userId : this.crtRequestAudioFile.userId,
              asrDomain : this.selectedAsrDomain['asrDomainId'],
              //this.audioTable[k].asrDomainId = audioElement.;
              status :'New',
              transcriptionJobId : response.body["Job ID"],
              audioId : this.crtRequestAudioFile.audioId
              
              }
              this.audioTable.push(tmpAudioJob);
              this.dataSource = new MatTableDataSource(this.audioTable);
              this.dataSource.sort = this.sort;
              this.updateStatusAfterTrasncript(response.body["Job ID"]);
          }else{
            this.translate.get('home.transcriptionAlreadyDone').subscribe((res: string) => { this.messageService.clear(); let message = res.split(":");this.messageService.add({ severity:'warn', summary: message[0], detail: message[1] , sticky:true});});
          
          }
        }
        

        
          
        },
        error: (err: any) => {
          let errorMessage = "";
          if(err.error.message){
             errorMessage = err.error.message;
            }else{
              errorMessage = err.message;
            }
          this.translate.get('home.transcriptionFailed').subscribe((res: string) => { this.messageService.clear(); let message = res.split(":");this.messageService.add({ severity:'warn', summary: message[0], detail: message[1]+ ": " +errorMessage, sticky:true});});
          console.log(err);
          return null;
        }
      });
    }
    this.closeModal(id);
  }
  
  updateStatusAfterTrasncript(audioId: any): void {
    this.updateAudioFileStatus(audioId);
    let crtStatus: any = '';
    for(let audioElement of this.audioTable) {
      if(audioElement.transcriptionJobId == audioId) {
        crtStatus = audioElement.status;
      }
    }
    if(crtStatus != 'completed') {
      setTimeout(()=>{               
        this.updateStatusAfterTrasncript(audioId);
    }, 2000);
    }
  }

  navigateToTranscription(jobId: number, audioId: number, fileName: string, asrDomain: string, length:any) {
    
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "jobId": jobId,
        "audioId": audioId,
        "fileName": fileName,
        "asrDomain": asrDomain,
        "length":length
      }
    };
    this.router.navigate(['/transcription'], navigationExtras);
  }

  openModal(id: string, audioFileInput: AudioFile) {
    this.selectedDownloadFormat = 'docx';
    this.asrDomainList = [];
    this.transcriptionsService.getAsrDomains().subscribe({
      next: (dataAsrDomains: { body: any; }) => {
        dataAsrDomains.body.forEach((element)=>this.asrDomainList.push(element));
        this.crtRequestAudioFile = audioFileInput;
        this.selectedAsrDomain = this.asrDomainList[0];
        this.requestModalService.open(id);
      },
      error: (err: any) => {
        console.log(err);
        return null;
      }
    });
  }

  openModalUpload(id: string){
    this.requestModalService.open(id);
  } 

  onUploadFilesChange(event:any){
      for (var i = 0; i <= event.target.files.length - 1; i++) {
        var selectedFile = event.target.files[i];
        if (selectedFile.name.toLowerCase().includes(".m4a") || selectedFile.name.toLowerCase().includes(".mp4") || selectedFile.name.toLowerCase().includes(".mp3") || selectedFile.name.toLowerCase().includes(".wav")){
          const uploadId = selectedFile.name.normalize("NFD").replaceAll(/[^\x20-\x7E]/g, "").replaceAll(" ","_").replaceAll(/[&\/\\#,+()$~%'"”:*?<>{}]/g, "")+ Math.random();
          this.uploadSubmit(selectedFile,uploadId)
        }else{
          this.translate.get('home.invalidFileType').subscribe((res: string) => { this.messageService.clear(); let message = res.split(":");this.messageService.add({ severity:'warn', summary: message[0], detail: selectedFile.name+": "+message[1], sticky:true});});
        }
    }
  }

  removeFile(crtFile: currentUploadFile){
    this.uploadProgress.set(crtFile.uploadId,-1);
    for (var i = 0; i <= this.currentUploadFiles.length - 1; i++) {
      if(crtFile.file.name==this.currentUploadFiles[i].file.name){
        this.currentUploadFiles.splice(i,1);
      } 
  }
    
  }

  closeModal(id: string) {
    this.requestModalService.close(id);
    if (id=="custom-modal-3"){
      this.currentUploadFiles=[];
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(filterValue: any) {
    this.crtFilter = filterValue;
    this.dataSource.filter = filterValue.value.trim().toLocaleLowerCase();
  }


  checkTokenFromStorage(){
    if (!!!this.tokenStorage.getToken()){
      this.translate.get('autoLogoutMessage').subscribe((res: string) => { this.messageService.clear(); let message = res.split(":");this.messageService.add({ severity:'warn', summary: message[0], detail: message[1], sticky:true});});
      this.tokenStorage.signOut();
      setTimeout(()=>{this.router.navigate(['/']);window.location.reload()},2000);
    }else{
    let current_time = new Date();
    let request_timeout = 10000;
    if ((current_time.getTime()-this.checked_token_time.getTime())>request_timeout){
      this.authService.info().subscribe({
        next: (data) => {

        },
        error:(data) => {
          this.translate.get('autoLogoutMessage').subscribe((res: string) => { this.messageService.clear(); let message = res.split(":");this.messageService.add({ severity:'warn', summary: message[0], detail: message[1], sticky:true});});
          this.tokenStorage.signOut();
          setTimeout(()=>{this.router.navigate(['/']);window.location.reload()},2000);
        }
      })
      this.checked_token_time = current_time;
    }

    }
    
  }
  

}
