import { Injectable } from '@angular/core';
const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const LANG_KEY = 'lang';
@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }

  signOut(): void {
    window.sessionStorage.clear();
    
  }

  public saveToken(token: string | null): void {
    if(token !== null) {
      window.sessionStorage.setItem(TOKEN_KEY, token);
    }
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.setItem(USER_KEY, user);
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return user;
    }
    return null;
  }


  public saveLang(lang: any): void {
    window.sessionStorage.setItem(LANG_KEY, lang);
  }

  public getLang(): any {
    const lang = window.sessionStorage.getItem(LANG_KEY);
    if (lang) {
      return lang;
    }
    return null;
  }
}