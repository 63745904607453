import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { TokenStorageService } from '../_services/token-storage.service';
import { TranslateService } from '@ngx-translate/core';
import {ErrorStateMatcher} from '@angular/material/core';
import { User } from '../_models/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  currentUser:any ;
  form: any = {
    firstName:null,
    lastName:null,
    username: null,
    password: null,
    retypePassword:null,
    speechToTextSeconds:null
  };
  timeRemaining:any = {
    hours:null,
    minutes:null,
    seconds:null
  }
  isPasswordMissmatch =false;
  isEmailMissmatch = false;
  isSuccessful = false;
  isSignUpFailed = false;
  isAccountCreated = false;
  isAccountFailed = false;
  changePass=false;
  changeEmail=false;
  errorMessage = '';
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

  constructor(private tokenStorageService: TokenStorageService,public translate: TranslateService, public authService: AuthService, public router:Router) { }
  ngOnInit(): void {
    this.changePass=false;
    this.changeEmail=false;
    this.isPasswordMissmatch =false;
    this.isEmailMissmatch = false;
    this.form.password=null;
    this.form.retypePassword=null;
    this.form.newEmail=null;
    this.form.retypeEmail= null;
    this.form.currentPassword= null;
    if (this.tokenStorageService.getToken()) {
      const user = this.tokenStorageService.getUser();
      
      this.authService.info().subscribe({
        next: (dataFile: { body: any; }) => {
          this.currentUser = new User(dataFile);
          this.form.username=this.currentUser.userEmail;
          this.form.firstName=this.currentUser.firstName;
          this.form.lastName=this.currentUser.lastName;
          this.timeRemaining.hours = Math.floor(this.currentUser.speechToTextSeconds / 3600); //1h
          this.timeRemaining.minutes = Math.floor((this.currentUser.speechToTextSeconds - (this.timeRemaining.hours * 3600))/60); //30m
          this.timeRemaining.seconds = Math.floor( (this.currentUser.speechToTextSeconds - (this.timeRemaining.hours * 3600)- (this.timeRemaining.minutes*60))*100)/100;
          this.form.speechToTextSeconds=this.timeRemaining.hours+ 'h '+ this.timeRemaining.minutes  + 'm ' +this.timeRemaining.seconds + 's';
        },
        error: (err: any) => {
          console.log(err);
          return null;
        }
      });

  }else{
    this.router.navigateByUrl("/");
  }
}

  onSubmit(): void {
    const { firstName , lastName, username, password,retypePassword, currentPassword, newEmail, retypeEmail } = this.form;
    console.log(this.currentUser.password);
    if (retypeEmail==null){
    if (retypePassword==password){
      this.isPasswordMissmatch = false;

        this.authService.updateInfoPassword(username,password ).subscribe({
          next: data => {
            this.isSuccessful = true;
            this.isSignUpFailed = false;
          },
          error: err => {
            this.errorMessage = err.error.message;
          }
        });
                
    }else{
        this.isPasswordMissmatch = true;
      }
    }
    if (retypePassword==null){
    if (newEmail==retypeEmail){
      this.isEmailMissmatch = false;

        this.authService.updateInfoEmail(newEmail,this.currentUser.password ).subscribe({
          next: data => {
            this.isSuccessful = true;
            this.isSignUpFailed = false;
          },
          error: err => {
            this.errorMessage = err.error.message;
          }
        });
      
      }else{
        this.isEmailMissmatch = true;
      }
    }
    


}
}
