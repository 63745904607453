
  <div class="table-button-container home"> 
      
    <button mat-fab color="warn" class="home-button" aria-label="Home" routerLink="/">
      <mat-icon>home</mat-icon>
    </button>

    <span class="files_text">{{ 'profile' | translate }}:</span>
    
</div>


<div class="table-button-container-profile ">
    <div class="card card-container">
      <mat-card>
        <mat-card-content>
          <form
            name="form"
            (ngSubmit)="f.form.valid && onSubmit()"
            #f="ngForm"
            novalidate
            class="format-form"
          >
          <table style="display: inline-table; width: 500px;">
            <tr>
                <td>
                    <div class="form-group email">
                        <mat-form-field appearance="outline" hideRequiredMarker> 
                        
                        <mat-label>{{ 'login.email' | translate }}</mat-label>
                        <input matInput
                        type="email"
                        [formControl]="emailFormControl" 
                        name="username"
                        [(ngModel)]="form.username"

                        placeholder="{{ 'login.intrEmail' | translate }}"
                        />
                        
                        <mat-error *ngIf="(emailFormControl.hasError('email')&& f.submitted) || (emailFormControl.hasError('required') && f.submitted)">
                            {{ 'login.invalidEmail' | translate }}
                        </mat-error>
                        </mat-form-field>
                        </div>
                        
                </td>
                <td></td>
                <td>
                    <div class="form-group email">
                        <mat-form-field appearance="outline" hideRequiredMarker> 
                        
                        <mat-label>{{ 'profile_page.credits' | translate }}</mat-label>
                        <input matInput
                        type="text"

                        name="speechToTextSeconds"
                        [(ngModel)]="form.speechToTextSeconds"
                        readonly
                        placeholder="{{ 'login.intrEmail' | translate }}"
                        />
                        </mat-form-field>
                        </div>

                </td>
                </tr>
                <tr>
                    <td>
                        <div class="form-group email">
                            <mat-form-field appearance="outline" hideRequiredMarker > 
                            
                            <mat-label>{{ 'login.lastName' | translate }}</mat-label>
                        <input matInput
                            type="text"
                            name="lastName"
                            [(ngModel)]="form.lastName"
                            required
                            readonly
                            #lastName="ngModel" 
                            placeholder="{{ 'login.lastNameIntr' | translate }}"
                            
                            required
                            />
                            
                            <mat-error *ngIf="lastName.hasError('required') && f.submitted">
                            {{ 'login.invalidLastName' | translate }}
                            </mat-error>
                        </mat-form-field>
                        </div>
                        </td>
                        <td style="display: flex; min-width:20px;">     </td>
                        <td>
                            <div class="form-group email">
                                <mat-form-field appearance="outline" hideRequiredMarker> 
                                
                                <mat-label>{{ 'login.firstName' | translate }}</mat-label>
                            <input matInput
                                type="text"
                                name="firstName"
                                [(ngModel)]="form.firstName"
                                required
                                readonly
                                #firstName="ngModel" 
                                placeholder="{{ 'login.firstNameIntr' | translate }}"
                                required
                                />
                                
                                <mat-error *ngIf="firstName.hasError('required') && f.submitted">
                                {{ 'login.invalidName' | translate }}
                                </mat-error>
                            </mat-form-field>
                            </div>
                            
                        </td>
                    </tr>

                    <tr  *ngIf="changeEmail"> 
                        <td>
                            <div class="form-group">
                        
                        
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'profile_page.newEmail' | translate }}</mat-label>
                                <input matInput
                                type="text"
                                name="newEmail"
                                [(ngModel)]="form.newEmail"
                                
                                required
                                #retypePassword="ngModel" 
                                placeholder="{{ 'profile_page.newEmail' | translate }}"
                                required
                                />

                                </mat-form-field>
                                
                                </div>
                        </td>
                        <td></td>
                        <td>
                            <div class="form-group">
                        
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'profile_page.confirmEmail' | translate }}</mat-label>
                                <input matInput
                                type="text"
                                name="retypeEmail"
                                [(ngModel)]="form.retypeEmail"
                                
                                required
                                #password="ngModel" 
                                placeholder="{{ 'profile_page.confirmEmail' | translate }}"

                                />
                                </mat-form-field>
                                
                                </div>
                        </td>
                        </tr>

                        <tr  *ngIf="changePass"> 
                            <td>
                                <div class="form-group">
                            
                            
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{ 'profile_page.newPassword' | translate }}</mat-label>
                                    <input matInput
                                    type="password"
                                    name="password"
                                    [(ngModel)]="form.password"
                                    
                                    required
                                    #retypePassword="ngModel" 
                                    placeholder="{{ 'profile_page.newPassword' | translate }}"
                                    required
                                    />

                                    </mat-form-field>
                                    
                                    </div>
                            </td>
                            <td></td>
                            <td>
                                <div class="form-group">
                            
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{ 'profile_page.confirmNewPassword' | translate }}</mat-label>
                                    <input matInput
                                    type="password"
                                    name="retypePassword"
                                    [(ngModel)]="form.retypePassword"
                                    required

                                    minlength="6"
                                    #password="ngModel" 
                                    placeholder="{{ 'profile_page.confirmNewPassword' | translate }}"

                                    />
                                    <mat-error *ngIf="retypePassword.hasError('required') && f.submitted" >{{ 'login.intrRetypePassword' | translate }}</mat-error>
                                    
                                    </mat-form-field>
                                    
                                    </div>
                            </td>
                            </tr>
                            <tr *ngIf="!changeEmail&&!changePass">
                                <td>
                                    
                                    <button mat-raised-button color="success" class="btn btn-primary btn-block text-login" style="width: 100%;" (click)="changeEmail=true"> 
                                        {{ 'profile_page.changeEmail' | translate }}
                                    </button>

                            </td>
                            <td></td>
                            <td>
                                <button mat-raised-button color="success" class="btn btn-primary btn-block text-login" style="width: 100%;"  (click)="changePass=true">
                                    {{ 'profile_page.changePassword' | translate }}
                                </button>
                            </td>
                            </tr>

                            <tr style="margin-top:50px" *ngIf="changeEmail" style="display: none;">
                                <td>
                                    
                                    <div class="form-group">
                            
                            
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{ 'profile_page.currentPassword' | translate }}</mat-label>
                                        <input matInput
                                        type="password"
                                        name="currentPassword"
                                        [(ngModel)]="form.currentPassword"
                                        
                                        required
                                        #retypePassword="ngModel" 
                                        placeholder="{{ 'profile_page.currentPassword' | translate }}"
                                        required
                                        />
    
                                        </mat-form-field>
                                        
                                        </div>

                            </td>
                            <td></td>
                            <td>

                            </td>
                            </tr>

                            <tr style="margin-top:50px" *ngIf="changeEmail||changePass">
                                <td>
                                    
                                    <button mat-raised-button color="success" class="btn btn-primary btn-block text-login" style="width: 100%;" (click)="onSubmit()"> 
                                        {{ 'profile_page.save' | translate }}
                                    </button>

                            </td>
                            <td></td>
                            <td>
                                <button mat-raised-button color="success" class="btn btn-primary btn-block text-login" style="width: 100%;" (click)="ngOnInit()" > 
                                    {{ 'profile_page.cancel' | translate }}
                                </button>
                            </td>
                            </tr>
            </table>

                <div class="form-group unauth">
                <mat-label> 
                <mat-error  *ngIf="f.submitted && isPasswordMissmatch" >
                    <h4 class="headertekst">{{ 'login.passwordMissmatch' | translate }}</h4>
                    
                </mat-error>
                </mat-label> 
    
                <mat-label> 
                    <mat-error  *ngIf="f.submitted && isEmailMissmatch" >
                        <h4 class="headertekst">{{ 'profile_page.emailMissmatch' | translate }}</h4>
                        
                    </mat-error>
                    </mat-label> 

                <mat-label> 
                <mat-error  *ngIf="authService.emailAlreadyInUse" >
                    <h4 class="headertekst">{{ 'login.emailAlreadyInUse' | translate }}</h4>
                    
                </mat-error>
                </mat-label> 
                
    

                
                </div>
          </form>
  
          </mat-card-content>
        </mat-card>
    </div>
  </div>